<template>
  <Page color="info">
    <template slot="title">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            {{ title }}
            <v-btn icon>
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </span>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in $_schoolClasses"
            :key="index"
            :to="{
              name: $route.name,
              params: { id: item.id },
              query: { search: search },
            }"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.code }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template slot="icons" v-if="schoolClass.id && !$vuetify.breakpoint.xs">
      <span class="ml-1">{{ loading ? "" : schoolClass.teacher.code }}</span>
      ,
      <span class="ml-1"
        >{{ schoolClass.studentCount.total }} Schüler:innen ({{
          schoolClass.studentCount.female
        }}
        <v-icon small>mdi-gender-female</v-icon>,
        {{ schoolClass.studentCount.male }}
        <v-icon small>mdi-gender-male</v-icon>
        <span v-if="schoolClass.studentCount.fluid > 0"
          >,
          {{ schoolClass.studentCount.fluid }}
          <v-icon small>mdi-gender-transgender</v-icon></span
        >)
      </span>
    </template>
    <template slot="extension" v-if="!$_hasRole('student')">
      <v-tabs background-color="info darken-1" rounded>
        <v-btn icon :to="{ name: 'SchoolClasses' }" exact
          ><v-icon>mdi-arrow-left</v-icon></v-btn
        >
        <v-tab :to="{ name: 'SchoolClassList' }">Schüler:innen</v-tab>
        <v-tab :to="{ name: 'SchoolClassRegister' }">Klassenbuch</v-tab>
        <v-tab :to="{ name: 'SchoolClassExams' }">Probenplan</v-tab>
        <v-tab :to="{ name: 'SchoolClassDocuments' }">Dokumente</v-tab>
        <v-tab :to="{ name: 'SchoolClassTeam' }">Klassenteam</v-tab>
      </v-tabs>
    </template>
    <router-view :schoolClass="schoolClass" @updated="fetchData"></router-view>
  </Page>
</template>

<script>
import { formatDatespan } from "common/utils/date.js";
import { sortPeople } from "common/utils/people.js";
export default {
  name: "SchoolClass",
  props: { id: Number },
  data() {
    return {
      loading: true,
      search: "",
      schoolClass: {},
    };
  },
  computed: {
    title() {
      if (this.loading) {
        return "Klasse";
      }
      return this.schoolClass.code;
    },
  },
  watch: {
    id() {
      this.fetchData();
    },
  },
  methods: {
    formatDatespan,
    async fetchData() {
      if (!this.id) return;
      this.loading = true;
      if (this.$_hasRole("staff")) {
        this.schoolClass = await this.apiGet({
          resource: "schoolclass/schoolclass",
          id: this.id,
        });

        this.schoolClass.students = sortPeople(this.schoolClass.students);
      }
      this.loading = false;
    },
  },
  async created() {
    await this.fetchData();
  },
};
</script>
